window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.resources = window.locales.am.resources || {};window.locales.am.resources.resources = window.locales.am.resources.resources || {};window.locales.am.resources.resources = {...window.locales.am.resources.resources, ...{
    "resources_title": "መርጃዎች",
    "admissions": "መግቢያዎች",
    "academic_and_program_support": "የአካዳሚክ <span class=\"sm:hidden md:inline\">እና የፕሮግራም</span> ድጋፍ",
    "career_services": "የሙያ አገልግሎቶች",
    "library_services": "የቤተ መፃህፍት አገልግሎቶች",
    "student_events": "የተማሪዎች ክስተቶች",
    "search_for_articles": "ጽሑፎችን ይፈልጉ",
    "search": "ፈልግ",
    "open_search": "ፍለጋን ክፈት",
    "close_search": "ፍለጋን ዝጋ",
    "category": "ምድብ",
    "select_a_category": "ምድብ ይምረጡ",
    "read_more": "ተጨማሪ ያንብቡ",
    "in_category": "በምድብ",
    "search_results_found": "የፍለጋ ውጤቶች ለ፡ ",
    "articles_count": "{count} {count, plural, =1 {ጽሑፍ} other {ጽሑፎች}}",
    "we_have_found": "አግኝተናል <span class=\"font-bold\">{count} {count, plural, =1 {ውጤት} other {ውጤቶች}}</span> ለዚህ ፍለጋ",
    "search_more_link_quantic": "https://support.quantic.edu/search?query={query}",
    "search_more_link_valar": "https://valar-support.quantic.edu/search?query={query}",
    "for_further_search_results": "ለተጨማሪ የፍለጋ ውጤቶች፣ ",
    "click_here": "እዚህ&nbsp;ጠቅ&nbsp;ያድርጉ",
    "articles": "መጣጥፎች",
    "academic_and_program_support_title": "የትምህርትና የስራማይ እንድርግ ድጋፍ",
    "academic_and_program_support_content_one_title": "የአካዳሚክ ድጋፍ",
    "academic_and_program_support_content_two_title": "የፕሮግራም ድጋፍ",
    "academic_and_program_support_content_two_description": "ስለ ተደራሽነት እና ማረፊያዎች ፣ የክፍያ መጠየቂያ እና የትምህርት ክፍያ ማካካሻ ፣ እና ግልባጭ ወይም ሌሎች የምዝገባ ሰነዶች ላይ እገዛን መስጠትን ጨምሮ የኳንቲክ ሰራተኞች እርስዎን ለመደገፍ እዚህ አሉ። እያንዳንዱ ቡድን ለጥያቄዎች መልስ የሚሰጥ፣ ውይይቶችን የሚያመቻች እና በፕሮግራሙ ውስጥ ለመበልጸግ የሚያስፈልግዎትን መረጃ መያዝ የሚችል የSlack አስተባባሪ ተመድቧል።",
    "can_t_find_what_you_re_looking_for": "የሚፈልጉትን ማግኘት አልቻሉም? ",
    "search_here": "እዚህ&nbsp;ይፈልጉ",
    "we_have_found_articles": "አግኝተናል <span class=\"font-bold\">{count}</span> {count, plural, =1 {ጽሑፍ} other {ጽሑፎች}}.",
    "career_services_title": "የሙያ አገልግሎቶች",
    "career_services_content_title": "የሙያ አገልግሎቶች መባዎች",
    "career_services_content_description": "እያንዳንዳችሁ ለትራንስፎርሜሽን ልምድ፣ ያ እውቀትዎን ለማሳደግ፣ ለሙያ ምሰሶ አዳዲስ ክህሎቶችን ማግኘት፣ ተመልካቾችዎን እና መድረክዎን በማዳበር ስራ ፈጣሪ ለመሆን፣ ወይም እንደ የንግድ ስራ መሪ በራስዎ ላይ ያለዎትን እምነት ማሳደግን ይጨምራል። . በጉዞዎ ውስጥ የትም ቢሆኑ፣ የሙያ አገልግሎቶች እርስዎን ለመርዳት እዚህ አሉ! <br><br> የቀጥታ እና ያልተመሳሰሉ ክፍለ ጊዜዎችን በማጣመር በቡድንም ሆነ በግለሰብ ቅርጸቶች፣ ከራስ ግምገማ እስከ የስራ ስልቶች፣ የቃለ መጠይቅ መሰናዶ እስከ ድርድር ድረስ ያሉትን የስራ ርዕሶችን እንሸፍናለን። ትምህርት ቤት፣ ስራ እና የእለት ተእለት ሀላፊነቶችን ስትዘዋወር፣ ግቦችህ እና የጊዜ ሰሌዳዎችህ ሊለወጡ ይችላሉ፣ እና ጊዜው ለእርስዎ ተስማሚ ሲሆን ከሙያ አገልግሎቶች ጋር መሳተፍ ትችላለህ።",
    "library_services_title": "የቤተ መፃህፍት አገልግሎቶች",
    "library_services_content_one_title": "ሕንፃ የለም? ችግር የሌም",
    "library_services_content_two_title": "ከመጻሕፍት በላይ",
    "library_services_content_two_description": "ምን ማድረግ እንዳለቦት እና እንዴት ማድረግ እንዳለቦት ብቻ ሳይሆን ለርስዎ እና ለታዳሚዎችዎ ደንበኞች፣ ደንበኞች ወይም ባለሀብቶች ለምን አስፈላጊ እንደሆነ ይማራሉ። <br>ወደ ቤተ-መጽሐፍትህ ❤️ ልትሄድ ነው።",
    "library_services_link_one_label": "Statista",
    "library_services_link_two_label": "One Business",
    "available_app_store": "በ App Store ይገኛል",
    "available_google_play": "በ Google Play ይገኛል",
    "copyright": "© Quantic Holdings, Inc. ሁሉም መብቶች የተጠበቁ ናቸው።",
    "homepage": "መነሻ ገጽ",
    "privacy_policy": "የግላዊነት ፖሊሲ",
    "terms_and_conditions": "ውሎች እና ሁኔታዎች",
    "cookies": "ኩኪዎች",
    "student_events_title": "የተማሪ ክስተቶች",
    "student_events_content_title": "የተማሪ ክስተቶች እና የአውታረ መረብ እድሎች",
    "student_events_content_description": "ወደ የተማሪ ዝግጅቶች እንኳን በደህና መጡ! በአካል ኮንፈረንሶችን፣ የተማሪ ድርጅቶችን፣ የጉዳይ ጥናት ውይይቶችን እና ሌሎችንም ጨምሮ በኳንቲክ ለጠንካራ አውታረ መረብ እና ከክፍል ውጪ የመማር እድሎች ሰፊ እድሎች አሉ። <br><br> በአካል በመገኘት ኮንፈረንስ ላይ መገኘት ይፈልጋሉ? የተማሪ ድርጅት ይጀመር? በከተማዎ ውስጥ ልዩ ዝግጅት ላይ ይገኙ? ግብዣዎቹን ይከታተሉ! እንዲሁም እንደ ቀጥታ የጉዳይ ጥናት ውይይቶች፣ ወቅታዊ ዌብናሮች፣ የቢሮ ሰአታት እና ሌሎችም ያሉ የአካዳሚክ ተሳትፎ እድሎችን እናመቻችላችኋለን፣ ስለዚህ በፕሮግራሙ በሙሉ ከአካዳሚክ ባለሞያዎቻችን ጋር የመገናኘት እድል ይኖርዎታል።",
    "welcome_message": "ተማሪዎቻችን ተናገሩ እኛም ሰምተናል! የኳንቲክ መተግበሪያ ጠቃሚ የፕሮግራም መረጃዎችን በቀላሉ የሚገኝ ለማድረግ የተዘጋጀ የመርጃዎች ክፍልን አሁን ያሳያል። <br> በፕሮግራምዎ ውስጥ ስኬታማ ለመሆን ከሚፈልጉት የአስተዳዳሪ መሳሪያዎች ማንኛውንም ነገር ለማግኘት ምንጮችን ይፈልጉ ከመመረቂያው በላይ ሊጠቀሙባቸው የሚችሉትን የሙያ ሀብቶች። <br><br> መልካም ትምህርት!",
    "close_welcome_message": "የእንኳን ደህና መጣችሁ መልእክት ዝጋ",
    "admissions_title": "መግቢያዎች",
    "admissions_content_title": "መግቢያዎች",
    "failed_to_load_articles": "ጽሑፎችን መጫን አልተሳካም።",
    "library_services_links_title": "የውሂብ ጎታ መዳረሻ",
    "academic_and_program_support_content_one_description": "የኳንቲክ አካዳሚክ አማካሪ ቡድን ጥናትዎን ለማቀድ፣ ስራዎን እና የህይወት ግዴታዎችን ሚዛን ለመጠበቅ እና አስፈላጊ ሆኖ ሲገኝ ወደ ትክክለኛው መንገድ እንዲመለሱ ያግዝዎታል። ስለ <a href=\"https://support.quantic.edu/article/860-academic-advising\" target=\"_blank\">አንድ ለአንድ የአካዳሚክ የምክር ክፍለ ጊዜዎች</a> የበለጠ ይረዱ።<br><br> ሁሉንም የአካዳሚክ ጥያቄዎችዎን ለመመለስ እና በፕሮጀክቶች፣ በዋና ድንጋዮች እና በሂደት ላይ ባሉ ሌሎች ስራዎች ላይ ለመመካከር <a href=\"https://quantic.edu/meet-our-academic-team/\" target=\"_blank\">የኳንቲክ መምህራን አባላት</a> ይገኛሉ። ስለ <a href=\"https://support.quantic.edu/article/861-faculty-office-hours\" target=\"_blank\">ፋኩልቲ የቢሮ ሰዓቶች</a> የበለጠ ይረዱ።",
    "library_services_links_description": "{brandNameShort} የተማሪዎችን በርካታ የአካዳሚክ የውሂብ ጎታዎችን ሙሉ መዳረሻ ይሰጣል—የተጠቃሚ ስም ወይም የይለፍ ቃል አያስፈልግም። እያንዳንዱን የውሂብ ጎታ ለመድረስ ከታች ያሉትን ማገናኛዎች ጠቅ ያድርጉ; እነዚህ አገናኞች ዕልባት ሊደረግባቸው እንደማይችል ልብ ይበሉ።",
    "search_more_link_execEd": "https://exec-ed-support.quantic.edu/search?query= {query}",
    "academic_and_program_support_content_two_description_short": "የኳንቲክ ሰራተኞች እርስዎን ለመደገፍ እዚህ አሉ፣ ስለ ተደራሽነት እና ማረፊያዎች ፣የክፍያ መጠየቂያ እና የትምህርት ክፍያ ክፍያ እና የመመዝገቢያ ሰነዶች ላይ እገዛን መስጠትን ጨምሮ።",
    "advising": "ማማከር",
    "advising_description": "የኳንቲክ አካዳሚክ አማካሪ ቡድን ጥናትዎን ለማቀድ፣ ስራዎን እና የህይወት ግዴታዎችን ሚዛን ለመጠበቅ እና አስፈላጊ ሆኖ ሲገኝ ወደ ትክክለኛው መንገድ እንዲመለሱ ያግዝዎታል።",
    "faculty": "ፋኩልቲ",
    "faculty_description": "ሁሉንም የአካዳሚክ ጥያቄዎችዎን ለመመለስ እና በፕሮጀክቶች፣ በዋና ድንጋዮች እና በሂደት ላይ ባሉ ሌሎች ስራዎች ላይ ለመመካከር <a href=\"https://quantic.edu/meet-our-academic-team/\" target=\"_blank\">የኳንቲክ መምህራን አባላት</a> ይገኛሉ። ስለ <a href=\"https://exec-ed-support.quantic.edu/article/1232-faculty-office-hours\" target=\"_blank\">ፋኩልቲ የቢሮ ሰዓቶች</a> የበለጠ ይረዱ።",
    "research": "ምርምር",
    "research_description": "ፕሮጄክቶችዎ ምርምር ሲፈልጉ፣ ለመጀመር እንዲረዱዎት የእኛ የቤተ-መጽሐፍት ባለሙያ ቀልጣፋ፣ ለመከተል ቀላል መመሪያዎችን ይሰጣል።",
    "career_services_content_description_alternate": "እያንዳንዳችሁ ለትራንስፎርሜሽን ልምድ እዚህ ናችሁ፣ እና የሙያ አገልግሎቶች አውታረ መረብዎን ለማሳደግ እና የአሁኑን እና የወደፊት የስራ ግቦችን ለማሳካት የምስክር ወረቀቶችዎን እንዲጠቀሙ ለማገዝ እዚህ መጥተዋል።<br /><br /> የቀጥታ እና የተቀዳ የቡድን ክፍለ ጊዜዎችን በማጣመር ከፕሮግራም እኩዮችህ፣ ቀጣሪዎችህ፣ ቅጥር አስተዳዳሪዎች እና የኢንዱስትሪ ባለሙያዎች ለመማር እና ለመሳተፍ እድል ይኖርሃል። በተጨማሪም, ጠቃሚ ምክሮችን እና ተጨማሪ መረጃ ለማግኘት በዚህ ክፍል ውስጥ ያሉትን ጽሑፎች ይመልከቱ.",
    "student_events_content_description_alternate": "ወደ የተማሪ ዝግጅቶች እንኳን በደህና መጡ! በኳንቲክ ለጠንካራ አውታረመረብ እና ከክፍል ውጭ ለመማር ሰፊ እድሎች አሉ።<br /><br /> እንደ ቀጥታ የጉዳይ ጥናት ውይይቶች፣ ወቅታዊ ዌብናሮች፣ የቢሮ ሰአታት እና ሌሎችም ያሉ ለምናባዊ ስብሰባዎች ግብዣዎችን፣ በከተማዎ ውስጥ ያሉ ልዩ ዝግጅቶችን እና የአካዳሚክ ተሳትፎ እድሎችን ይከታተሉ። በፕሮግራሙ በሙሉ ከአካዳሚክ ኤክስፐርቶች፣ አብረውን ከሚማሩ ተማሪዎች እና የቀድሞ ተማሪዎች ጋር የመገናኘት እድል ይኖርዎታል።",
    "library_services_link_three_label": "IBISWorld",
    "admissions_content_pre_application_description": "<div><p>የማመልከቻዎ ሂደት በተቀላጠፈ ሁኔታ መሄዱን ለማረጋገጥ የእኛ የመግቢያ ቡድን እዚህ አለ። ስለተለያዩ ፕሮግራሞቻችን፣ የተማሪዎቻችን እና የምሩቃን ኔትዎርክ፣ መጪ የመግቢያ ዝግጅቶች፣ የትምህርት እና የስኮላርሺፕ መረጃዎች፣ እንዲሁም አጠቃላይ ጥያቄዎችን ስለ ምርጫ መስፈርቶች እና የመግቢያ ቀነ-ገደቦች ጥያቄዎችን ለመመለስ እዚህ መጥተናል።<br><br> <a href=\"/settings/application\">ማመልከቻዎን አሁን ማጠናቀቅ</a> ወይም በ <a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a> ከእኛ ጋር መገናኘት ይችላሉ።</p><br><p> ከታች እንደ የመተግበሪያ ተደጋጋሚ ጥያቄዎች፣ የመግቢያ ስታቲስቲክስ እና የቅርብ ጊዜ የክፍል ብልሽቶች፣ ለኩባንያ ትምህርት ጥቅማጥቅሞች አጋዥ መመሪያዎች እና ሌሎችም ያሉ መርጃዎችን ያገኛሉ። እርስዎን ለማግኘት መጠበቅ አንችልም!</p></div>",
    "admissions_content_post_application_description": "<div><p>የማመልከቻዎ ሂደት በተቀላጠፈ ሁኔታ መሄዱን ለማረጋገጥ የእኛ የመግቢያ ቡድን እዚህ አለ። ስለተለያዩ ፕሮግራሞቻችን፣ የተማሪዎቻችን እና የምሩቃን ኔትዎርክ፣ መጪ የመግቢያ ዝግጅቶች፣ የትምህርት እና የስኮላርሺፕ መረጃዎች፣ እንዲሁም አጠቃላይ ጥያቄዎችን ስለ ምርጫ መስፈርቶች እና የመግቢያ ቀነ-ገደቦች ጥያቄዎችን ለመመለስ እዚህ መጥተናል።<br><br> በ <a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a> ላይ በቀጥታ ከእኛ ጋር መገናኘት ይችላሉ.</p><br><p> ከታች እንደ የመተግበሪያ ተደጋጋሚ ጥያቄዎች፣ የመግቢያ ስታቲስቲክስ እና የቅርብ ጊዜ የክፍል ብልሽቶች፣ ለኩባንያ ትምህርት ጥቅማጥቅሞች አጋዥ መመሪያዎች እና ሌሎችም ያሉ መርጃዎችን ያገኛሉ። እርስዎን ለማግኘት መጠበቅ አንችልም!</p></div>",
    "library_services_content_three_title": "የኮርስ ማጠቃለያ መጽሐፍ",
    "library_services_content_three_description": "በእያንዳንዱ ኮርስ ውስጥ፣ የተማርከውን አጭር ማጠቃለያ የሚያቀርብ አንድ ወይም ከዚያ በላይ ፒዲኤፍ ማግኘት አለህ። እነዚህን ማጠቃለያዎች ከተመረቁ በኋላ ከእርስዎ ጋር ሊወስዱት ወደሚችሉት ዲጂታል መጽሐፍ አዘጋጅተናል።",
    "library_services_content_one_description": "ዘመናዊ ፕሮግራሞቻችን ዘመናዊ ቤተ መጻሕፍት ይገባቸዋል። የኢንዱስትሪ-ምርጥ <a href=\"https://support.quantic.edu/article/952-databases\" target=\"_blank\">የምርምር መሳሪያዎች</a> ፣ ተደራሽ 24/7። ለመጀመር እንዲረዳዎ ቀልጣፋ፣ ለመከተል ቀላል <a href=\"https://support.quantic.edu/article/953-guides\" target=\"_blank\">መመሪያዎች</a> ። በቤተ መፃህፍት የሚመሩ ዌብናሮች እና የምርምር <a href=\"https://calendly.com/quantic-library/office-hours\" target=\"_blank\">አማካሪዎች</a> . እንዴት <a href=\"https://support.quantic.edu/article/954-citations\" target=\"_blank\">እንደሚጠቅሱ እንኳን እናስተምርዎታለን።</a>"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.resources = window.locales.ar.resources || {};window.locales.ar.resources.resources = window.locales.ar.resources.resources || {};window.locales.ar.resources.resources = {...window.locales.ar.resources.resources, ...{
    "resources_title": "موارد",
    "admissions": "القبول",
    "academic_and_program_support": "البرامج <span class=\"sm:hidden md:inline\">ودعم</span> الأكاديمية",
    "career_services": "الخدمات المهنية",
    "library_services": "خدمات المكتبة",
    "student_events": "أحداث الطلاب",
    "search_for_articles": "ابحث عن مقالات",
    "search": "يبحث",
    "open_search": "افتح البحث",
    "close_search": "اغلاق البحث",
    "category": "فئة",
    "select_a_category": "اختر الفئة",
    "read_more": "اقرأ أكثر",
    "in_category": "في الفئة",
    "search_results_found": "نتائج البحث عن: ",
    "articles_count": "{count} {count, plural, =1 {شرط} other {مقالات}}",
    "we_have_found": "لقد وجدنا <span class=\"font-bold\">{count} {count, plural, =1 {نتيجة} other {نتائج}}</span> لهذا البحث",
    "search_more_link_quantic": "https://support.quantic.edu/search?query={query}",
    "search_more_link_valar": "https://valar-support.quantic.edu/search?query={query}",
    "for_further_search_results": "لمزيد من نتائج البحث ، ",
    "click_here": "انق&nbsp;هنا",
    "articles": "مقالات",
    "academic_and_program_support_title": "الدعم الأكاديمي والبرنامج",
    "academic_and_program_support_content_one_title": "الدعم الأكاديمي",
    "academic_and_program_support_content_two_title": "دعم البرامج",
    "academic_and_program_support_content_two_description": "يتواجد فريق Quantic هنا لدعمك ، بما في ذلك تقديم المساعدة في الأسئلة المتعلقة بإمكانية الوصول والإقامة ، وسداد الفواتير والرسوم الدراسية ، والنصوص أو مستندات التسجيل الأخرى. يتم أيضًا تعيين منسق Slack لكل مجموعة يمكنه الإجابة على الأسئلة ، وتسهيل المناقشات ، والتأكد من أن لديك المعلومات التي تحتاجها لتزدهر في البرنامج.",
    "search_here": "ابحث&nbsp;هنا",
    "we_have_found_articles": "لقد وجدنا <span class=\"font-bold\">{count}</span> {count, plural, =1 {شرط} other {مقالات}}.",
    "career_services_title": "الخدمات المهنية",
    "career_services_content_title": "عروض الخدمات الوظيفية",
    "career_services_content_description": "كل واحد منكم هنا من أجل تجربة تحويلية ، سواء كان ذلك يتضمن تعزيز معرفتك للترقية ، أو اكتساب مهارات جديدة من أجل محور وظيفي ، أو تطوير جمهورك ومنصتك لتصبح رائد أعمال ، أو زيادة ثقتك بنفسك كقائد أعمال . أينما كنت في رحلتك ، فإن Career Services هنا لدعمك! <br><br> من خلال مجموعة من الجلسات الحية وغير المتزامنة ، بصيغتين جماعية وفردية ، نغطي موضوعات مهنية من التقييم الذاتي إلى استراتيجيات العمل ، والتحضير للمقابلة والتفاوض على عروض العمل. أثناء تنقلك في المدرسة والعمل والمسؤوليات اليومية ، قد تتغير أهدافك وجداولك الزمنية ، ويمكنك التعامل مع الخدمات المهنية عندما يكون التوقيت مناسبًا لك.",
    "library_services_content_one_title": "لا مبنى؟ لا مشكلة",
    "library_services_content_two_title": "أكثر من مجرد كتب",
    "library_services_content_two_description": "لن تتعلم فقط ما يجب فعله وكيفية القيام به ، بل ستتعلم أيضًا سبب أهميته لك ولجمهورك سواء كانوا عملاء أو عملاء أو مستثمرين. <br>أنت ذاهب إلى ❤️ مكتبتك.",
    "library_services_link_one_label": "Statista",
    "library_services_link_two_label": "One Business",
    "available_app_store": "متاح على App Store",
    "available_google_play": "متاح على Google Play",
    "copyright": "© Quantic Holdings, Inc. جميع الحقوق محفوظة.",
    "homepage": "الصفحة الرئيسية",
    "privacy_policy": "سياسة الخصوصية",
    "terms_and_conditions": "الشروط والأحكام",
    "cookies": "بسكويت",
    "student_events_title": "أحداث الطلاب",
    "student_events_content_title": "أحداث الطلاب وفرص التواصل",
    "student_events_content_description": "مرحبًا بكم في أحداث الطلاب! هناك فرص كبيرة للتواصل القوي وفرص التعلم خارج الفصل الدراسي في Quantic ، بما في ذلك المؤتمرات الشخصية والمنظمات الطلابية ومناقشات دراسة الحالة والمزيد. <br><br> هل تريد حضور مؤتمر شخصيًا؟ بدء منظمة طلابية؟ حضور حدث خاص في مدينتك؟ ترقب الدعوات! نقوم أيضًا بتسهيل فرص المشاركة الأكاديمية ، مثل مناقشات دراسة الحالة الحية ، والندوات الموضوعية على الويب ، وساعات العمل ، وغير ذلك ، حتى تتاح لك الفرصة للتواصل مع خبرائنا الأكاديميين خلال البرنامج.",
    "welcome_message": "لقد تحدث طلابنا واستمعنا! يضم تطبيق Quantic الآن قسمًا للموارد مخصصًا لإتاحة معلومات البرنامج المهمة بسهولة. <br> ابحث في الموارد للعثور على أي شيء من الأدوات الإدارية التي تحتاجها للنجاح في برنامجك إلى الموارد المهنية التي يمكنك استخدامها بعد التخرج. <br><br> دراسة سعيدة!",
    "close_welcome_message": "إغلاق رسالة الترحيب",
    "admissions_title": "القبول",
    "admissions_content_title": "القبول",
    "can_t_find_what_you_re_looking_for": "ألا تستطيع إيجاد ما تبحث عنه؟",
    "library_services_title": "خدمات المكتبة",
    "failed_to_load_articles": "فشل تحميل المقالات.",
    "library_services_links_title": "الوصول إلى قاعدة البيانات",
    "academic_and_program_support_content_one_description": "يمكن لفريق الإرشاد الأكاديمي كوانتيك مساعدتك في التخطيط لدراستك وتحقيق التوازن بين العمل والتزامات الحياة والعودة إلى المسار الصحيح عند الضرورة. تعرف على المزيد حول <a href=\"https://support.quantic.edu/article/860-academic-advising\" target=\"_blank\">جلسات الإرشاد الأكاديمي الفردية</a> .<br><br> <a href=\"https://quantic.edu/meet-our-academic-team/\" target=\"_blank\">أعضاء هيئة التدريس Quantic</a> متاحون للإجابة على جميع أسئلتك الأكاديمية والتشاور بشأن المشاريع ، والتخرجات ، والأعمال الأخرى الجارية. تعرف على المزيد حول <a href=\"https://support.quantic.edu/article/861-faculty-office-hours\" target=\"_blank\">ساعات عمل أعضاء هيئة التدريس</a> .",
    "library_services_links_description": "يوفر {brandNameShort} للطلاب وصولاً كاملاً إلى العديد من قواعد البيانات الأكاديمية - دون الحاجة إلى اسم مستخدم أو كلمة مرور. انقر فوق الروابط أدناه للوصول إلى كل قاعدة بيانات ؛ لاحظ أنه لا يمكن وضع إشارة مرجعية على هذه الروابط.",
    "search_more_link_execEd": "https://exec-ed-support.quantic.edu/search?query= {query}",
    "academic_and_program_support_content_two_description_short": "موظفو Quantic موجودون هنا لدعمك، بما في ذلك تقديم المساعدة في الأسئلة المتعلقة بإمكانية الوصول والإقامة، والفواتير وسداد الرسوم الدراسية، ووثائق التسجيل.",
    "advising": "تقديم المشورة",
    "advising_description": "يمكن لفريق الإرشاد الأكاديمي Quantic مساعدتك في التخطيط لدراستك، وتحقيق التوازن بين العمل والالتزامات الحياتية، والعودة إلى المسار الصحيح عند الضرورة.",
    "faculty": "كلية",
    "faculty_description": "<a href=\"https://quantic.edu/meet-our-academic-team/\" target=\"_blank\">أعضاء هيئة التدريس في Quantic</a> متاحون للإجابة على جميع أسئلتك الأكاديمية وللتشاور بشأن المشاريع والمشاريع النهائية وغيرها من الأعمال قيد التنفيذ. اعرف المزيد عن <a href=\"https://exec-ed-support.quantic.edu/article/1232-faculty-office-hours\" target=\"_blank\">الساعات المكتبية لأعضاء هيئة التدريس</a> .",
    "research": "بحث",
    "research_description": "عندما تتطلب مشروعاتك البحث، يقدم أمين المكتبة لدينا أدلة فعالة وسهلة المتابعة لمساعدتك على البدء.",
    "career_services_content_description_alternate": "كل واحد منكم موجود هنا للحصول على تجربة تحويلية، والخدمات المهنية هنا للمساعدة في ضمان الاستفادة من شهاداتك لتنمية شبكتك وتحقيق أهدافك المهنية الحالية والمستقبلية.<br /><br /> من خلال مجموعة من الجلسات الجماعية المباشرة والمسجلة، ستتاح لك الفرصة للتعلم من أقرانك في البرنامج، والقائمين بالتوظيف، ومديري التوظيف، وخبراء الصناعة، والتفاعل معهم. بالإضافة إلى ذلك، راجع المقالات الموجودة في هذا القسم للحصول على نصائح ومعلومات إضافية.",
    "student_events_content_description_alternate": "مرحبا بكم في الأحداث الطلابية! هناك فرص كبيرة للتواصل القوي والتعلم خارج الفصل الدراسي في Quantic.<br /><br /> ترقب الدعوات لعقد اجتماعات افتراضية، والمناسبات الخاصة في مدينتك، وفرص المشاركة الأكاديمية، مثل مناقشات دراسة الحالة المباشرة، والندوات عبر الإنترنت الموضعية، وساعات العمل، والمزيد. ستتاح لك الفرصة للتواصل مع خبرائنا الأكاديميين وزملائك الطلاب والخريجين طوال البرنامج.",
    "library_services_link_three_label": "IBISWorld",
    "admissions_content_pre_application_description": "<div style=\";text-align:right;direction:rtl\"><p style=\";text-align:right;direction:rtl\">فريق القبول لدينا موجود هنا لضمان سير عملية التقديم بسلاسة. نحن هنا للإجابة على الأسئلة حول برامجنا المختلفة، وشبكة طلابنا وخريجينا، وفعاليات القبول القادمة، ومعلومات الرسوم الدراسية والمنح الدراسية، بالإضافة إلى الأسئلة العامة حول معايير الاختيار ومواعيد القبول.<br><br> يمكنك <a href=\"/settings/application\">إكمال طلبك</a> الآن أو التواصل معنا على <a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a> .</p><br><p style=\";text-align:right;direction:rtl\"> ستجد أدناه موارد مثل الأسئلة الشائعة حول التقديم وإحصائيات القبول وتفاصيل الفصول الدراسية الأخيرة وأدلة مفيدة حول مزايا الرسوم الدراسية للشركات والمزيد. لا نستطيع الانتظار لمقابلتك!</p></div>",
    "admissions_content_post_application_description": "<div style=\";text-align:right;direction:rtl\"><p style=\";text-align:right;direction:rtl\">فريق القبول لدينا موجود هنا لضمان سير عملية التقديم بسلاسة. نحن هنا للإجابة على الأسئلة حول برامجنا المختلفة، وشبكة طلابنا وخريجينا، وفعاليات القبول القادمة، ومعلومات الرسوم الدراسية والمنح الدراسية، بالإضافة إلى الأسئلة العامة حول معايير الاختيار ومواعيد القبول.<br><br> يمكنك التواصل معنا مباشرة على <a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a> .</p><br><p style=\";text-align:right;direction:rtl\"> ستجد أدناه موارد مثل الأسئلة الشائعة حول التقديم وإحصائيات القبول وتفاصيل الفصول الدراسية الأخيرة وأدلة مفيدة حول مزايا الرسوم الدراسية للشركات والمزيد. لا نستطيع الانتظار لمقابلتك!</p></div>",
    "library_services_content_three_title": "ملخص المقرر الدراسي",
    "library_services_content_three_description": "في كل دورة، يمكنك الوصول إلى ملف PDF واحد أو أكثر يقدم ملخصًا موجزًا لما تعلمته. لقد قمنا بتجميع هذه الملخصات في كتاب رقمي يمكنك اصطحابه معك بعد التخرج.",
    "library_services_content_one_description": "تستحق برامجنا الحديثة مكتبة حديثة. أفضل <a href=\"https://support.quantic.edu/article/952-databases\" target=\"_blank\">أدوات البحث</a> في الصناعة، متاحة على مدار الساعة طوال أيام الأسبوع. <a href=\"https://support.quantic.edu/article/953-guides\" target=\"_blank\">أدلة</a> فعّالة وسهلة المتابعة لمساعدتك على البدء. ندوات عبر الإنترنت <a href=\"https://calendly.com/quantic-library/office-hours\" target=\"_blank\">واستشارات</a> بحثية بقيادة أمناء المكتبة. سنعلمك أيضًا كيفية <a href=\"https://support.quantic.edu/article/954-citations\" target=\"_blank\">الاستشهاد.</a>"
}
};window.locales.en = window.locales.en || {};window.locales.en.resources = window.locales.en.resources || {};window.locales.en.resources.resources = window.locales.en.resources.resources || {};window.locales.en.resources.resources = {...window.locales.en.resources.resources, ...{
    "resources_title": "Resources",
    "admissions": "Admissions",
    "academic_and_program_support": "Academic <span class=\"sm:hidden md:inline\">and Program</span> Support",
    "career_services": "Career Services",
    "library_services": "Library Services",
    "student_events": "Student Events",
    "search_for_articles": "Search for Articles",
    "search": "Search",
    "open_search": "Open Search",
    "close_search": "Close Search",
    "category": "Category",
    "select_a_category": "Select Category",
    "read_more": "Read more",
    "in_category": "In category",
    "search_results_found": "Search results for: ",
    "articles_count": "{count} {count, plural, =1 {article} other {articles}}",
    "we_have_found": "We have found <span class=\"font-bold\">{count} {count, plural, =1 {result} other {results}}</span> for this search",
    "search_more_link_quantic": "https://support.quantic.edu/search?query={query}",
    "search_more_link_valar": "https://valar-support.quantic.edu/search?query={query}",
    "search_more_link_execEd": "https://exec-ed-support.quantic.edu/search?query={query}",
    "for_further_search_results": "For further search results, ",
    "click_here": "click&nbsp;here",
    "articles": "Articles",
    "academic_and_program_support_title": "Academic and Program Support",
    "academic_and_program_support_content_one_title": "Academic Support",
    "academic_and_program_support_content_one_description": "The Quantic Academic Advising Team can help you plan your studies, balance work and life obligations, and get back on track when necessary. Learn more about <a href=\"https://support.quantic.edu/article/860-academic-advising\" target=\"_blank\">one-on-one Academic Advising sessions</a>. <br><br><a href=\"https://quantic.edu/meet-our-academic-team/\" target=\"_blank\">Quantic faculty members</a> are available to answer all of your academic questions and to consult on projects, capstones, and other work in progress. Learn more about <a href=\"https://support.quantic.edu/article/861-faculty-office-hours\" target=\"_blank\">faculty office hours</a>.",
    "academic_and_program_support_content_two_title": "Program Support",
    "academic_and_program_support_content_two_description": "Quantic staff are here to support you, including offering help with questions about accessibility and accommodations, billing and tuition reimbursement, and transcripts or other enrollment documents. Each cohort is also assigned a Slack coordinator who can answer questions, facilitate discussions, and make sure that you have the information you need to thrive in the program.",
    "academic_and_program_support_content_two_description_short": "Quantic staff are here to support you, including offering help with questions about accessibility and accommodations, billing and tuition reimbursement, and enrollment documents.",
    "advising": "Advising",
    "advising_description": "The Quantic Academic Advising Team can help you plan your studies, balance work and life obligations, and get back on track when necessary.",
    "faculty": "Faculty",
    "faculty_description": "<a href=\"https://quantic.edu/meet-our-academic-team/\" target=\"_blank\">Quantic faculty members</a> are available to answer all of your academic questions and to consult on projects, capstones, and other work-in-progress. Learn more about <a href=\"https://exec-ed-support.quantic.edu/article/1232-faculty-office-hours\" target=\"_blank\">faculty office hours</a>.",
    "research": "Research",
    "research_description": "When your projects require research, our librarian offers efficient, easy-to-follow guides to help you get started.",
    "can_t_find_what_you_re_looking_for": "Can't find what you're looking for? ",
    "search_here": "Search&nbsp;here",
    "we_have_found_articles": "We have found <span class=\"font-bold\">{count}</span> {count, plural, =1 {article} other {articles}}.",
    "career_services_title": "Career Services",
    "career_services_content_title": "Career Services Offerings",
    "career_services_content_description": "Each of you is here for a transformational experience, whether that includes enhancing your knowledge for a promotion, gaining new skills for a career pivot, developing your audience and platform to become an entrepreneur, or increasing the confidence you have in yourself as a business leader. Wherever you are in your journey, Career Services is here to support you! <br><br> Through a combination of live and asynchronous sessions, in both group and individual formats, we cover career topics from self-assessment to job strategies, interview prep to negotiating job offers. As you navigate school, work, and everyday responsibilities, your goals and timelines may shift, and you can engage with Career Services when the timing is right for you.",
    "career_services_content_description_alternate": "Each of you is here for a transformational experience, and Career Services is here to help ensure you leverage your certificates to grow your network and meet your current and future career goals.<br /><br /> Through a combination of live and recorded group sessions, you will have the opportunity to learn from and engage with your program peers, recruiters, hiring managers, and industry experts. Additionally, check out the articles in this section for tips and additional information.",
    "library_services_title": "Library Services",
    "library_services_content_one_title": "No building? No problem",
    "library_services_content_one_description": "Our modern programs deserve a modern library. Industry-best <a href=\"https://support.quantic.edu/article/952-databases\" target=\"_blank\">research tools</a>, accessible 24/7. Efficient, easy-to-follow <a href=\"https://support.quantic.edu/article/953-guides\" target=\"_blank\">guides</a> to help you get started. Librarian-led webinars and research <a href=\"https://calendly.com/quantic-library/office-hours\" target=\"_blank\">consults</a>. We’ll even teach you how to <a href=\"https://support.quantic.edu/article/954-citations\" target=\"_blank\">cite.</a>",
    "library_services_content_two_title": "More than books",
    "library_services_content_two_description": "You’ll learn not just what to do and how to do it, but why it’s important to you and your audience whether they are customers, clients or investors. <br>You’re going to ❤️ your library.",
    "library_services_content_three_title": "Course Summary Book",
    "library_services_content_three_description": "Within each course, you have access to one or more PDFs providing a succinct summary of what you've learned. We've compiled these summaries into a digital book that you can take with you after graduation.",
    "library_services_links_title": "Database Access",
    "library_services_links_description": "{brandNameShort} provides students full access to several academic databases—no username or password required. Click the links below to access each database; note that these links cannot be bookmarked.",
    "library_services_link_one_label": "Statista",
    "library_services_link_two_label": "One Business",
    "library_services_link_three_label": "IBISWorld",
    "available_app_store": "Available App Store",
    "available_google_play": "Available Google Play",
    "copyright": "© Quantic Holdings, Inc. All rights reserved.",
    "homepage": "Homepage",
    "privacy_policy": "Privacy Policy",
    "terms_and_conditions": "Terms and Conditions",
    "cookies": "Cookies",
    "student_events_title": "Student Events",
    "student_events_content_title": "Student Events and Networking Opportunities",
    "student_events_content_description": "Welcome to Student Events! There are ample opportunities for robust networking and out-of-classroom learning opportunities at Quantic, including in-person conferences, student organizations, case study discussions, and more. <br><br> Want to attend an in-person conference? Start a Student Organization? Attend a special event in your city? Keep an eye out for the invites! We also facilitate academic engagement opportunities, such as live case study discussions, topical webinars, office hours, and more, so you’ll have the opportunity to connect with our academic experts throughout the program.",
    "student_events_content_description_alternate": "Welcome to student events! There are ample opportunities for robust networking and out-of-classroom learning at Quantic.<br /><br />Keep an eye out for invitations to virtual meetups, special events in your city, and academic engagement opportunities, such as live case study discussions, topical webinars, office hours, and more. You’ll have the opportunity to connect with our academic experts, fellow students, and alumni throughout the program.",
    "welcome_message": "Our students have spoken and we've listened! The Quantic app now features a Resources section dedicated to making important program information easily available.<br>Search Resources to find anything from the administrative tools you need to succeed in your program to career resources that you can use beyond graduation.<br><br>Happy studying!",
    "close_welcome_message": "Close welcome message",
    "admissions_title": "Admissions",
    "admissions_content_title": "Admissions",
    "admissions_content_pre_application_description": "<div><p>Our Admissions Team is here to ensure that your application process runs smoothly. We’re here to answer questions about our different programs, our student and alumni network, upcoming Admissions events, tuition and scholarship information, as well as general questions about selection criteria and admission deadlines.<br><br>You can <a href=\"/settings/application\">complete your application</a> now or connect with us at <a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a>.</p><br><p>Below you will find resources such as application FAQs, admissions statistics and recent class breakdowns, helpful guides to company tuition benefits, and much more. We can’t wait to meet you!</p></div>",
    "admissions_content_post_application_description": "<div><p>Our Admissions Team is here to ensure that your application process runs smoothly. We’re here to answer questions about our different programs, our student and alumni network, upcoming Admissions events, tuition and scholarship information, as well as general questions about selection criteria and admission deadlines.<br><br>You can connect with us directly at <a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a>.</p><br><p>Below you will find resources such as application FAQs, admissions statistics and recent class breakdowns, helpful guides to company tuition benefits, and much more. We can’t wait to meet you!</p></div>",
    "failed_to_load_articles": "Failed to load articles."
}
};window.locales.es = window.locales.es || {};window.locales.es.resources = window.locales.es.resources || {};window.locales.es.resources.resources = window.locales.es.resources.resources || {};window.locales.es.resources.resources = {...window.locales.es.resources.resources, ...{
    "resources_title": "Recursos",
    "admissions": "Admisiones",
    "academic_and_program_support": "Apoyo Académico <span class=\"sm:hidden md:inline\">y de Programa</span>",
    "career_services": "Servicios de Carrera",
    "library_services": "Servicios de Biblioteca",
    "student_events": "Estudiantes Eventos",
    "search_for_articles": "Buscar Artículos",
    "search": "Buscar",
    "open_search": "Abrir Búsqueda",
    "close_search": "Cerrar Búsqueda",
    "category": "Categoría",
    "select_a_category": "Seleccione Categoría",
    "read_more": "Leer más",
    "in_category": "En categoría",
    "search_results_found": "Resultados de búsqueda para: ",
    "articles_count": "{count} {count, plural, =1 {artículo} other {artículos}}",
    "we_have_found": "Nosotros hemos encontrado <span class=\"font-bold\">{count} {count, plural, =1 {resultado} other {resultados}}</span> para esta búsqueda",
    "search_more_link_quantic": "https://support.quantic.edu/search?query={query}",
    "search_more_link_valar": "https://valar-support.quantic.edu/search?query={query}",
    "for_further_search_results": "Para obtener más resultados de búsqueda, ",
    "click_here": "haga&nbsp;clic&nbsp;aquí",
    "articles": "Artículos",
    "academic_and_program_support_title": "Apoyo Académico y del Programa",
    "academic_and_program_support_content_one_title": "Apoyo Académico",
    "academic_and_program_support_content_two_title": "Apoyo al Programa",
    "academic_and_program_support_content_two_description": "El personal de Quantic está aquí para brindarle apoyo, lo que incluye ayudarlo con preguntas sobre accesibilidad y adaptaciones, facturación y reembolso de matrícula, y transcripciones u otros documentos de inscripción. A cada cohorte también se le asigna un coordinador de Slack que puede responder preguntas, facilitar discusiones y asegurarse de que tenga la información que necesita para prosperar en el programa.",
    "can_t_find_what_you_re_looking_for": "¿No encuentras lo que buscas? ",
    "search_here": "Busca&nbsp;aquí",
    "we_have_found_articles": "Hemos encontrado <span class=\"font-bold\">{count}</span> {count, plural, =1 {artículo} other {artículos}}.",
    "career_services_title": "Servicios de carrera",
    "career_services_content_title": "Ofertas de servicios profesionales",
    "career_services_content_description": "Cada uno de ustedes está aquí para vivir una experiencia transformadora, ya sea que eso incluya mejorar su conocimiento para una promoción, adquirir nuevas habilidades para un giro profesional, desarrollar su audiencia y plataforma para convertirse en empresario o aumentar la confianza que tiene en sí mismo como líder empresarial. . ¡Dondequiera que esté en su viaje, Career Services está aquí para apoyarlo! <br><br> A través de una combinación de sesiones en vivo y asincrónicas, tanto en formato grupal como individual, cubrimos temas profesionales desde la autoevaluación hasta estrategias laborales, preparación para entrevistas y negociación de ofertas laborales. A medida que navega por la escuela, el trabajo y las responsabilidades cotidianas, sus objetivos y plazos pueden cambiar, y puede participar en Career Services cuando sea el momento adecuado para usted.",
    "library_services_title": "Servicios de biblioteca",
    "library_services_content_one_title": "¿Sin edificio? Ningún problema",
    "library_services_content_two_title": "Más que libros",
    "library_services_content_two_description": "Aprenderá no solo qué hacer y cómo hacerlo, sino también por qué es importante para usted y su audiencia, ya sean clientes o inversores. <br>Vas a ❤️ tu biblioteca.",
    "library_services_link_one_label": "Statista",
    "library_services_link_two_label": "One Business",
    "available_app_store": "Disponible en App Store",
    "available_google_play": "Disponible en Google Play",
    "copyright": "© Quantic Holdings, Inc. Todos los derechos reservados.",
    "homepage": "Página principal",
    "privacy_policy": "Política de privacidad",
    "terms_and_conditions": "Términos y condiciones",
    "cookies": "Cookies",
    "student_events_title": "Eventos estudiantiles",
    "student_events_content_title": "Eventos para estudiantes y oportunidades para establecer contactos",
    "student_events_content_description": "¡Bienvenidos a Eventos Estudiantiles! Hay amplias oportunidades para una sólida creación de redes y oportunidades de aprendizaje fuera del aula en Quantic, incluidas conferencias en persona, organizaciones estudiantiles, discusiones de estudios de casos y más. <br><br> ¿Quieres asistir a una conferencia en persona? ¿Iniciar una organización estudiantil? ¿Asistir a un evento especial en tu ciudad? ¡Estén atentos a las invitaciones! También facilitamos oportunidades de participación académica, como discusiones de estudios de casos en vivo, seminarios web temáticos, horarios de oficina y más, para que tenga la oportunidad de conectarse con nuestros expertos académicos durante todo el programa.",
    "welcome_message": "¡Nuestros estudiantes han hablado y nosotros hemos escuchado! La aplicación Quantic ahora cuenta con una sección de Recursos dedicada a hacer que la información importante del programa esté fácilmente disponible.<br> Busque recursos para encontrar cualquier cosa, desde las herramientas administrativas que necesita para tener éxito en su programa hasta recursos profesionales que puede usar después de graduarse.<br><br> ¡Feliz estudiando!",
    "close_welcome_message": "Cerrar mensaje de bienvenida",
    "admissions_title": "Admisiones",
    "admissions_content_title": "Admisiones",
    "failed_to_load_articles": "No se pudieron cargar los artículos.",
    "library_services_links_title": "Acceso a la base de datos",
    "academic_and_program_support_content_one_description": "El equipo de asesoramiento académico de Quantic puede ayudarlo a planificar sus estudios, equilibrar el trabajo y las obligaciones de la vida, y retomar el rumbo cuando sea necesario. Obtenga más información sobre <a href=\"https://support.quantic.edu/article/860-academic-advising\" target=\"_blank\">las sesiones individuales de asesoramiento académico</a> .<br><br> <a href=\"https://quantic.edu/meet-our-academic-team/\" target=\"_blank\">Los miembros de la facultad de Quantic</a> están disponibles para responder todas sus preguntas académicas y consultar sobre proyectos, culminaciones y otros trabajos en curso. Obtenga más información sobre <a href=\"https://support.quantic.edu/article/861-faculty-office-hours\" target=\"_blank\">el horario de atención de los profesores</a> .",
    "library_services_links_description": "{brandNameShort} brinda a los estudiantes acceso completo a varias bases de datos académicas, sin necesidad de nombre de usuario ni contraseña. Haga clic en los enlaces a continuación para acceder a cada base de datos; Tenga en cuenta que estos enlaces no se pueden marcar.",
    "search_more_link_execEd": "https://exec-ed-support.quantic.edu/search?query= {query}",
    "academic_and_program_support_content_two_description_short": "El personal de Quantic está aquí para ayudarlo, incluso ofreciéndole ayuda con preguntas sobre accesibilidad y adaptaciones, facturación y reembolso de matrícula, y documentos de inscripción.",
    "advising": "Asesoramiento",
    "advising_description": "El equipo de asesoramiento académico de Quantic puede ayudarle a planificar sus estudios, equilibrar las obligaciones laborales y vitales y retomar el rumbo cuando sea necesario.",
    "faculty": "Facultad",
    "faculty_description": "<a href=\"https://quantic.edu/meet-our-academic-team/\" target=\"_blank\">Los miembros del cuerpo docente de Quantic</a> están disponibles para responder todas sus preguntas académicas y realizar consultas sobre proyectos, culminaciones y otros trabajos en progreso. Obtenga más información sobre <a href=\"https://exec-ed-support.quantic.edu/article/1232-faculty-office-hours\" target=\"_blank\">el horario de atención de los profesores</a> .",
    "research": "Investigación",
    "research_description": "Cuando sus proyectos requieren investigación, nuestro bibliotecario ofrece guías eficientes y fáciles de seguir para ayudarlo a comenzar.",
    "career_services_content_description_alternate": "Cada uno de ustedes está aquí para vivir una experiencia transformadora, y Career Services está aquí para ayudarlo a aprovechar sus certificados para hacer crecer su red y alcanzar sus objetivos profesionales actuales y futuros.<br /><br /> A través de una combinación de sesiones grupales en vivo y grabadas, tendrá la oportunidad de aprender e interactuar con sus compañeros del programa, reclutadores, gerentes de contratación y expertos de la industria. Además, consulte los artículos de esta sección para obtener consejos e información adicional.",
    "student_events_content_description_alternate": "¡Bienvenidos a los eventos estudiantiles! En Quantic existen amplias oportunidades para establecer contactos sólidos y aprender fuera del aula.<br /><br /> Esté atento a las invitaciones a reuniones virtuales, eventos especiales en su ciudad y oportunidades de participación académica, como debates de estudios de casos en vivo, seminarios web temáticos, horarios de oficina y más. Tendrá la oportunidad de conectarse con nuestros expertos académicos, compañeros de estudios y ex alumnos durante todo el programa.",
    "library_services_link_three_label": "IBISWorld",
    "admissions_content_pre_application_description": "<div><p>Nuestro equipo de admisiones está aquí para garantizar que su proceso de solicitud se desarrolle sin problemas. Estamos aquí para responder preguntas sobre nuestros diferentes programas, nuestra red de estudiantes y exalumnos, próximos eventos de admisiones, información sobre matrículas y becas, así como preguntas generales sobre criterios de selección y fechas límite de admisión.<br><br> Puede <a href=\"/settings/application\">completar su solicitud</a> ahora o comunicarse con nosotros en <a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a> .</p><br><p> A continuación, encontrará recursos como preguntas frecuentes sobre solicitudes, estadísticas de admisión y desgloses de clases recientes, guías útiles sobre los beneficios de matrícula de la empresa y mucho más. ¡Estamos ansiosos por conocerlo!</p></div>",
    "admissions_content_post_application_description": "<div><p>Nuestro equipo de admisiones está aquí para garantizar que su proceso de solicitud se desarrolle sin problemas. Estamos aquí para responder preguntas sobre nuestros diferentes programas, nuestra red de estudiantes y exalumnos, próximos eventos de admisiones, información sobre matrículas y becas, así como preguntas generales sobre criterios de selección y fechas límite de admisión.<br><br> Puede comunicarse con nosotros directamente en <a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a> .</p><br><p> A continuación, encontrará recursos como preguntas frecuentes sobre solicitudes, estadísticas de admisión y desgloses de clases recientes, guías útiles sobre los beneficios de matrícula de la empresa y mucho más. ¡Estamos ansiosos por conocerlo!</p></div>",
    "library_services_content_three_title": "Libro resumen del curso",
    "library_services_content_three_description": "En cada curso, tienes acceso a uno o más archivos PDF que ofrecen un resumen sucinto de lo que has aprendido. Hemos recopilado estos resúmenes en un libro digital que puedes llevar contigo después de graduarte.",
    "library_services_content_one_description": "Nuestros programas modernos merecen una biblioteca moderna. Las mejores <a href=\"https://support.quantic.edu/article/952-databases\" target=\"_blank\">herramientas de investigación</a> de la industria, accesibles las 24 horas, los 7 días de la semana. <a href=\"https://support.quantic.edu/article/953-guides\" target=\"_blank\">Guías</a> eficientes y fáciles de seguir para ayudarlo a comenzar. Seminarios web y <a href=\"https://calendly.com/quantic-library/office-hours\" target=\"_blank\">consultas</a> de investigación dirigidos por bibliotecarios. Incluso le enseñaremos a <a href=\"https://support.quantic.edu/article/954-citations\" target=\"_blank\">citar.</a>"
}
};window.locales.it = window.locales.it || {};window.locales.it.resources = window.locales.it.resources || {};window.locales.it.resources.resources = window.locales.it.resources.resources || {};window.locales.it.resources.resources = {...window.locales.it.resources.resources, ...{
    "resources_title": "Risorse",
    "admissions": "Ammissioni",
    "academic_and_program_support": "Supporto Accademico <span class=\"sm:hidden md:inline\"> e di Programma</span>",
    "career_services": "Servizi di Carriera",
    "library_services": "Servizi Bibliotecari",
    "student_events": "Eventi Studenti",
    "search_for_articles": "Cerca Articoli",
    "search": "Ricerca",
    "open_search": "Apri Ricerca",
    "close_search": "Chiudi Ricerca",
    "category": "Categoria",
    "select_a_category": "Seleziona Categoria",
    "read_more": "Per saperne di più",
    "in_category": "Nella categoria",
    "search_results_found": "Cerca risultati per: ",
    "articles_count": "{count} {count, plural, =1 {articolo} other {articoli}}",
    "we_have_found": "Abbiamo trovato <span class=\"font-bold\">{count} {count, plural, =1 {risultato} other {risultati}}</span> per questa ricerca",
    "search_more_link_quantic": "https://support.quantic.edu/search?query={query}",
    "search_more_link_valar": "https://valar-support.quantic.edu/search?query={query}",
    "for_further_search_results": "Per ulteriori risultati di ricerca, ",
    "click_here": "clicca&nbsp;qui",
    "articles": "Articoli",
    "academic_and_program_support_title": "Supporto Accademico e di Programma",
    "academic_and_program_support_content_one_title": "Supporto Accademico",
    "academic_and_program_support_content_two_title": "Supporto al Programma",
    "academic_and_program_support_content_two_description": "Lo staff di Quantic è qui per supportarti, anche offrendo aiuto con domande su accessibilità e alloggi, fatturazione e rimborso delle tasse scolastiche e trascrizioni o altri documenti di iscrizione. A ogni coorte viene inoltre assegnato un coordinatore Slack che può rispondere alle domande, facilitare le discussioni e assicurarsi di disporre delle informazioni necessarie per avere successo nel programma.",
    "can_t_find_what_you_re_looking_for": "Non riesci a trovare quello che stai cercando? ",
    "search_here": "Cerca&nbsp;qui",
    "we_have_found_articles": "Abbiamo trovato <span class=\"font-bold\">{count}</span> {count, plural, =1 {articolo} other {articoli}}.",
    "career_services_title": "Servizi di carriera",
    "career_services_content_title": "Offerte di servizi di carriera",
    "career_services_content_description": "Ognuno di voi è qui per un'esperienza di trasformazione, che includa il miglioramento delle proprie conoscenze per una promozione, l'acquisizione di nuove competenze per un perno di carriera, lo sviluppo del proprio pubblico e della piattaforma per diventare un imprenditore o l'aumento della fiducia che si ha in se stessi come leader aziendale . Ovunque tu sia nel tuo viaggio, Career Services è qui per supportarti! <br><br> Attraverso una combinazione di sessioni dal vivo e asincrone, sia in formato di gruppo che individuale, trattiamo argomenti di carriera dall'autovalutazione alle strategie di lavoro, dalla preparazione al colloquio alla negoziazione di offerte di lavoro. Mentre esplori la scuola, il lavoro e le responsabilità quotidiane, i tuoi obiettivi e le tempistiche potrebbero cambiare e puoi interagire con i servizi di carriera quando è il momento giusto per te.",
    "library_services_title": "Servizi bibliotecari",
    "library_services_content_one_title": "Nessun edificio? Nessun problema",
    "library_services_content_two_title": "Altro che libri",
    "library_services_content_two_description": "Imparerai non solo cosa fare e come farlo, ma perché è importante per te e per il tuo pubblico, siano essi clienti, clienti o investitori. <br>Stai per ❤️ la tua libreria.",
    "library_services_link_one_label": "Statista",
    "library_services_link_two_label": "One Business",
    "available_app_store": "Disponibile App Store",
    "available_google_play": "Disponibile Google Play",
    "copyright": "© Quantic Holdings, Inc. Tutti i diritti riservati.",
    "homepage": "Homepage",
    "privacy_policy": "Informativa sulla Privacy",
    "terms_and_conditions": "Termini e Condizioni",
    "cookies": "Cookies",
    "student_events_title": "Eventi studenteschi",
    "student_events_content_title": "Eventi per studenti e opportunità di networking",
    "student_events_content_description": "Benvenuti agli eventi per studenti! Ci sono ampie opportunità per un solido networking e opportunità di apprendimento fuori dalla classe in Quantic, comprese conferenze di persona, organizzazioni studentesche, discussioni di casi di studio e altro ancora. <br><br> Vuoi partecipare a una conferenza di persona? Avviare un'organizzazione studentesca? Partecipare ad un evento speciale nella tua città? Occhio agli inviti! Facilitiamo anche opportunità di impegno accademico, come discussioni di casi di studio dal vivo, webinar di attualità, orari d'ufficio e altro ancora, così avrai l'opportunità di entrare in contatto con i nostri esperti accademici durante tutto il programma.",
    "welcome_message": "I nostri studenti hanno parlato e noi abbiamo ascoltato! L'app Quantic ora presenta una sezione Risorse dedicata a rendere facilmente disponibili importanti informazioni sul programma.<br> Cerca risorse per trovare qualsiasi cosa, dagli strumenti amministrativi di cui hai bisogno per avere successo nel tuo programma alle risorse di carriera che puoi utilizzare dopo la laurea.<br><br> Buono studio!",
    "close_welcome_message": "Chiudi il messaggio di benvenuto",
    "admissions_title": "Ammissioni",
    "admissions_content_title": "Ammissioni",
    "failed_to_load_articles": "Impossibile caricare gli articoli.",
    "library_services_links_title": "Accesso alla banca dati",
    "academic_and_program_support_content_one_description": "Il Quantic Academic Advising Team può aiutarti a pianificare i tuoi studi, bilanciare il lavoro e gli impegni della vita e rimetterti in carreggiata quando necessario. Ulteriori informazioni sulle <a href=\"https://support.quantic.edu/article/860-academic-advising\" target=\"_blank\">sessioni individuali di consulenza accademica</a> .<br><br> <a href=\"https://quantic.edu/meet-our-academic-team/\" target=\"_blank\">I membri della facoltà quantica</a> sono disponibili per rispondere a tutte le tue domande accademiche e per consultarsi su progetti, pietre miliari e altri lavori in corso. Ulteriori informazioni sugli <a href=\"https://support.quantic.edu/article/861-faculty-office-hours\" target=\"_blank\">orari di ricevimento della facoltà</a> .",
    "library_services_links_description": "{brandNameShort} fornisce agli studenti l'accesso completo a diversi database accademici, senza bisogno di nome utente o password. Fare clic sui collegamenti sottostanti per accedere a ciascun database; si noti che questi collegamenti non possono essere aggiunti ai segnalibri.",
    "search_more_link_execEd": "https://exec-ed-support.quantic.edu/search?query= {query}",
    "academic_and_program_support_content_two_description_short": "Lo staff Quantic è qui per supportarti, offrendoti anche aiuto con domande su accessibilità e alloggi, fatturazione e rimborso delle tasse scolastiche e documenti di iscrizione.",
    "advising": "Consulenza",
    "advising_description": "Il team di consulenza accademica di Quantic può aiutarti a pianificare i tuoi studi, a bilanciare gli obblighi di lavoro e di vita e a rimetterti in carreggiata quando necessario.",
    "faculty": "Facoltà",
    "faculty_description": "<a href=\"https://quantic.edu/meet-our-academic-team/\" target=\"_blank\">I membri della facoltà di Quantic</a> sono disponibili per rispondere a tutte le tue domande accademiche e per consulenze su progetti, pietre miliari e altri lavori in corso. Scopri di più sugli <a href=\"https://exec-ed-support.quantic.edu/article/1232-faculty-office-hours\" target=\"_blank\">orari di ricevimento della facoltà</a> .",
    "research": "Ricerca",
    "research_description": "Quando i tuoi progetti richiedono ricerche, il nostro bibliotecario offre guide efficienti e facili da seguire per aiutarti a iniziare.",
    "career_services_content_description_alternate": "Ognuno di voi è qui per un'esperienza di trasformazione e i Career Services sono qui per aiutarvi a sfruttare i vostri certificati per far crescere la vostra rete e raggiungere i vostri obiettivi di carriera attuali e futuri.<br /><br /> Attraverso una combinazione di sessioni di gruppo dal vivo e registrate, avrai l'opportunità di imparare e interagire con colleghi del programma, reclutatori, responsabili delle assunzioni ed esperti del settore. Inoltre, consulta gli articoli in questa sezione per suggerimenti e informazioni aggiuntive.",
    "student_events_content_description_alternate": "Benvenuti agli eventi studenteschi! In Quantic ci sono ampie opportunità per un solido networking e per l'apprendimento fuori dall'aula.<br /><br /> Tieni d'occhio gli inviti a incontri virtuali, eventi speciali nella tua città e opportunità di coinvolgimento accademico, come discussioni di casi di studio dal vivo, webinar di attualità, orari di ricevimento e altro ancora. Avrai l'opportunità di entrare in contatto con i nostri esperti accademici, altri studenti ed ex studenti durante tutto il programma.",
    "library_services_link_three_label": "IBISWorld",
    "admissions_content_pre_application_description": "<div><p>Il nostro Admissions Team è qui per garantire che il tuo processo di candidatura proceda senza intoppi. Siamo qui per rispondere a domande sui nostri diversi programmi, sulla nostra rete di studenti e alumni, sui prossimi eventi di ammissione, sulle informazioni su tasse universitarie e borse di studio, nonché a domande generali sui criteri di selezione e sulle scadenze di ammissione.<br><br> Puoi <a href=\"/settings/application\">completare la tua domanda</a> ora o contattarci all'indirizzo <a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a> .</p><br><p> Di seguito troverete risorse come FAQ sulle domande di iscrizione, statistiche di ammissione e recenti suddivisioni delle classi, utili guide sui benefit aziendali e molto altro. Non vediamo l'ora di incontrarvi!</p></div>",
    "admissions_content_post_application_description": "<div><p>Il nostro Admissions Team è qui per garantire che il tuo processo di candidatura proceda senza intoppi. Siamo qui per rispondere a domande sui nostri diversi programmi, sulla nostra rete di studenti e alumni, sui prossimi eventi di ammissione, sulle informazioni su tasse universitarie e borse di studio, nonché a domande generali sui criteri di selezione e sulle scadenze di ammissione.<br><br> Puoi contattarci direttamente scrivendo a <a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a> .</p><br><p> Di seguito troverete risorse come FAQ sulle domande di iscrizione, statistiche di ammissione e recenti suddivisioni delle classi, utili guide sui benefit aziendali e molto altro. Non vediamo l'ora di incontrarvi!</p></div>",
    "library_services_content_three_title": "Riassunto del corso Libro",
    "library_services_content_three_description": "All'interno di ogni corso, hai accesso a uno o più PDF che forniscono un riassunto succinto di ciò che hai imparato. Abbiamo compilato questi riassunti in un libro digitale che puoi portare con te dopo la laurea.",
    "library_services_content_one_description": "I nostri programmi moderni meritano una biblioteca moderna. I migliori <a href=\"https://support.quantic.edu/article/952-databases\" target=\"_blank\">strumenti di ricerca</a> del settore, accessibili 24 ore su 24, 7 giorni su 7. <a href=\"https://support.quantic.edu/article/953-guides\" target=\"_blank\">Guide</a> efficienti e facili da seguire per aiutarti a iniziare. Webinar e <a href=\"https://calendly.com/quantic-library/office-hours\" target=\"_blank\">consulenze</a> di ricerca guidate da bibliotecari. Ti insegneremo persino come <a href=\"https://support.quantic.edu/article/954-citations\" target=\"_blank\">citare.</a>"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.resources = window.locales.zh.resources || {};window.locales.zh.resources.resources = window.locales.zh.resources.resources || {};window.locales.zh.resources.resources = {...window.locales.zh.resources.resources, ...{
    "resources_title": "资源",
    "admissions": "招生",
    "academic_and_program_support": "学术和项目支持",
    "career_services": "就业服务",
    "library_services": "图书馆服务",
    "student_events": "学生活动",
    "search_for_articles": "搜索文章",
    "search": "搜索",
    "open_search": "打开搜索",
    "close_search": "关闭搜索",
    "category": "类别",
    "select_a_category": "选择类别",
    "read_more": "阅读更多",
    "in_category": "在类别中",
    "search_results_found": "为。。。。寻找结果：",
    "articles_count": "{count} {count, plural, =1 {文章} other {文章}}",
    "we_have_found": "我们发现 <span class=\"font-bold\">{count} {count, plural, =1 {结果} other {结果}}</span> 对于这个搜索",
    "search_more_link_quantic": "https://support.quantic.edu/search?query={query}",
    "search_more_link_valar": "https://valar-support.quantic.edu/search?query={query}",
    "for_further_search_results": "如需进一步搜索结果， ",
    "click_here": "点击这里",
    "articles": "文章",
    "academic_and_program_support_title": "学术与项目支持",
    "academic_and_program_support_content_one_title": "学术支持",
    "academic_and_program_support_content_two_title": "计划支持",
    "academic_and_program_support_content_two_description": "Quantic 工作人员随时为您提供支持，包括就无障碍设施和住宿、账单和学费报销以及成绩单或其他注册文件等问题提供帮助。 每个队列还分配了一名 Slack 协调员，他可以回答问题、促进讨论并确保您拥有在该计划中茁壮成长所需的信息。",
    "can_t_find_what_you_re_looking_for": "找不到您要找的东西？ ",
    "search_here": "在这里搜索",
    "we_have_found_articles": "我们发现 <span class=\"font-bold\">{count}</span> {count, plural, =1 {文章} other {文章}}.",
    "career_services_title": "职业服务",
    "career_services_content_title": "职业服务产品",
    "career_services_content_description": "你们每个人来到这里都是为了获得变革性的体验，无论这包括增强您的晋升知识，获得职业发展的新技能，发展您的受众和平台以成为企业家，还是增强您作为商业领袖对自己的信心 . 无论您身在何处，职业服务都在这里为您提供支持！ <br><br> 通过现场和异步会议的结合，以小组和个人的形式，我们涵盖了从自我评估到工作策略、面试准备到谈判工作机会的职业主题。 在您驾驭学校、工作和日常职责时，您的目标和时间表可能会发生变化，您可以在合适的时间参与职业服务。",
    "library_services_title": "图书馆服务",
    "library_services_content_one_title": "没有建筑？ 没问题",
    "library_services_content_two_title": "不仅仅是书",
    "library_services_content_two_description": "你不仅会学到做什么和怎么做，还会学到为什么这对你和你的听众很重要，无论他们是客户、委托人还是投资者。 <br>你要去 ❤️ 你的图书馆。",
    "library_services_link_one_label": "Statista",
    "library_services_link_two_label": "One Business",
    "available_app_store": "可用应用商店",
    "available_google_play": "可用应用商店",
    "copyright": "© Quantic Holdings, Inc。保留所有权利。",
    "homepage": "主页",
    "privacy_policy": "隐私政策",
    "terms_and_conditions": "条款和条件",
    "cookies": "缓存文件",
    "student_events_title": "学生活动",
    "student_events_content_title": "学生活动和交流机会",
    "student_events_content_description": "欢迎参加学生活动！ 在 Quantic，有充足的机会建立强大的网络和课外学习机会，包括面对面的会议、学生组织、案例研究讨论等。 <br><br> 想参加面对面的会议？ 成立学生组织？ 参加您所在城市的特别活动？ 留意邀请函！ 我们还促进学术参与机会，例如现场案例研究讨论、专题网络研讨会、办公时间等，因此您将有机会在整个计划中与我们的学术专家联系。",
    "welcome_message": "我们的学生说了，我们听了！ Quantic 应用程序现在有一个资源部分，专门用于轻松获取重要的程序信息。<br> 搜索资源以查找从您在计划中取得成功所需的管理工具到毕业后可以使用的职业资源的任何内容。<br><br> 学习愉快！",
    "close_welcome_message": "关闭欢迎信息",
    "admissions_title": "招生",
    "admissions_content_title": "招生",
    "failed_to_load_articles": "无法加载文章。",
    "library_services_links_title": "数据库访问",
    "academic_and_program_support_content_one_description": "Quantic 学术咨询团队可以帮助您规划学习、平衡工作和生活义务，并在必要时重回正轨。了解更多关于<a href=\"https://support.quantic.edu/article/860-academic-advising\" target=\"_blank\">一对一学术咨询会议的</a>信息。<br><br> <a href=\"https://quantic.edu/meet-our-academic-team/\" target=\"_blank\">Quantic 教职员工</a>可以回答您所有的学术问题，并就项目、顶点和其他正在进行的工作提供咨询。详细了解<a href=\"https://support.quantic.edu/article/861-faculty-office-hours\", target=\"_blank\">教师办公时间</a>。",
    "library_services_links_description": "{brandNameShort}为学生提供对多个学术数据库的完全访问权限，无需用户名或密码。单击下面的链接访问每个数据库；请注意，这些链接无法添加书签。",
    "search_more_link_execEd": "https://exec-ed-support.quantic.edu/search?query= {query}",
    "academic_and_program_support_content_two_description_short": "Quantic 工作人员随时为您提供支持，包括提供有关无障碍和住宿、账单和学费报销以及注册文件等问题的帮助。",
    "advising": "提供建议",
    "advising_description": "Quantic 学术咨询团队可以帮助您规划学习、平衡工作和生活义务，并在必要时回到正轨。",
    "faculty": "学院",
    "faculty_description": "<a href=\"https://quantic.edu/meet-our-academic-team/\" target=\"_blank\">Quantic 教职人员</a>可以回答您的所有学术问题，并就项目、顶点和其他正在进行的工作提供咨询。了解有关<a href=\"https://exec-ed-support.quantic.edu/article/1232-faculty-office-hours\", target=\"_blank\">教师办公时间</a>的更多信息。",
    "research": "研究",
    "research_description": "当您的项目需要研究时，我们的图书馆员会提供高效、易于遵循的指南来帮助您入门。",
    "career_services_content_description_alternate": "你们每个人来这里都是为了获得变革性的体验，职业服务将帮助确保您利用您的证书来发展您的网络并实现您当前和未来的职业目标。<br /><br />通过现场和录制的小组会议相结合，您将有机会向项目同行、招聘人员、招聘经理和行业专家学习并与之互动。此外，请查看本节中的文章以获取提示和其他信息。",
    "student_events_content_description_alternate": "欢迎参加学生活动！ Quantic 有充足的机会进行强大的网络和课外学习。<br /><br />请留意虚拟聚会、您所在城市的特别活动的邀请以及学术参与机会，例如现场案例研究讨论、专题网络研讨会、办公时间等。在整个项目中，您将有机会与我们的学术专家、同学和校友联系。",
    "library_services_link_three_label": "IBISWorld",
    "admissions_content_pre_application_description": "<div><p>我们的招生团队将确保您的申请过程顺利进行。我们将为您解答有关我们不同课程、学生和校友网络、即将举办的招生活动、学费和奖学金信息以及有关选拔标准和录取截止日期的一般问题。<br><br>您现在可以<a href=\"/settings/application\">完成申请</a>，或者通过<a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a>与我们联系。</p><br><p>下面您将找到资源，例如申请常见问题解答、招生统计数据和最近的课程分类、公司学费福利的实用指南等等。我们迫不及待地想见到您！</p></div>",
    "admissions_content_post_application_description": "<div><p>我们的招生团队将确保您的申请过程顺利进行。我们将为您解答有关我们不同课程、学生和校友网络、即将举办的招生活动、学费和奖学金信息以及有关选拔标准和录取截止日期的一般问题。<br><br>您可以通过<a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a>直接与我们联系。</p><br><p>下面您将找到资源，例如申请常见问题解答、招生统计数据和最近的课程分类、公司学费福利的实用指南等等。我们迫不及待地想见到您！</p></div>",
    "library_services_content_three_title": "课程总结书",
    "library_services_content_three_description": "在每门课程中，您都可以访问一个或多个 PDF，这些 PDF 提供了您所学内容的简明摘要。我们将这些摘要汇编成一本电子书，您可以在毕业后随身携带。",
    "library_services_content_one_description": "我们的现代项目理应拥有一个现代图书馆。业界最佳的<a href=\"https://support.quantic.edu/article/952-databases\" target=\"_blank\">研究工具</a>，全天候可用。高效、易于遵循的<a href=\"https://support.quantic.edu/article/953-guides\" target=\"_blank\">指南</a>可帮助您入门。图书管理员主导的网络研讨会和研究<a href=\"https://calendly.com/quantic-library/office-hours\" target=\"_blank\">咨询</a>。我们甚至会教您如何<a href=\"https://support.quantic.edu/article/954-citations\" target=\"_blank\">引用。</a>"
}
};